var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-fifths"},[_c('DefaultForm',{attrs:{"is-saving":_vm.getBaseDataCustomerIsSaving,"mode":_vm.mode},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$tc('general.customer', 1)),"type":_vm.getBaseDataCustomerValidationErrors &&
                _vm.getBaseDataCustomerValidationErrors.customer
                  ? 'is-danger'
                  : '',"message":_vm.getBaseDataCustomerValidationErrors &&
                _vm.getBaseDataCustomerValidationErrors.customer
                  ? _vm.getBaseDataCustomerValidationErrors.customer
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(
                    _vm.$t('modules.base_data.customer.placeholder.customer')
                  ),"required":""},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1)],1),_c('div',[(_vm.mode !== 'create')?_c('AddressesForm',{attrs:{"form-settings":formSettings,"disabled-fields":['family_name', 'given_name'],"validation-errors":_vm.getBaseDataCustomerValidationErrors &&
                _vm.Json.dot2Nested({ ..._vm.getBaseDataCustomerValidationErrors })
                  .addresses
                  ? _vm.Json.dot2Nested({
                      ..._vm.getBaseDataCustomerValidationErrors,
                    }).addresses
                  : ''},model:{value:(_vm.addresses),callback:function ($$v) {_vm.addresses=$$v},expression:"addresses"}}):_vm._e()],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }