
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import SaveButton from "@/components/buttons/table_actions/SaveButton.vue";
import ICustomer from "@/interfaces/ICustomer";
import AddressesForm from "@/components/forms/common/AddressesForm.vue";
import IAddress from "@/interfaces/IAddress";
import Json from "../../../helper/Json";
import { mixins } from "vue-class-component";
import { CustomerStoreMixin } from "@/mixins/Stores/CustomerStoreMixin";
import { BaseDataCustomerStoreMixin } from "@/mixins/Stores/BaseData/CustomerStoreMixin";

const CustomerModule = namespace("customer");

@Component({
  computed: {
    Json() {
      return Json;
    },
  },
  components: { AddressesForm, SaveButton, DefaultForm },
  mixins: [CustomerStoreMixin, FormMixin],
})
export default class CustomerForm extends mixins(BaseDataCustomerStoreMixin) {
  protected name = "CustomerForm.vue";

  /*
  @CustomerModule.Action("load")
  protected loadCustomersAction: any;

  @CustomerModule.Getter("getIsLoading")
  protected isLoading: any;

  @CustomerModule.Getter("getIsSaving")
  protected isSaving: any;

  @CustomerModule.Getter("getValidationErrors")
  protected getValidationErrors: any;

  @CustomerModule.Getter("getError")
  protected getError: any;
  
   */

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: null })
  public data!: ICustomer;

  protected id?: number;

  protected customer = "";
  protected addresses: Array<IAddress> = [];
  protected createdAt?: Date | string;
  protected updatedAt?: Date | string;

  @Watch("data", { immediate: true, deep: true })
  protected onDataChanged(data: ICustomer) {
    if (data) {
      this.setFormValues(data);
    }
  }

  public getFormValues(): ICustomer {
    let customer: ICustomer = {
      customer: this.customer,
      addresses: this.addresses.map((address: IAddress) => {
        return Json.removeNulls(address);
      }),
    };
    if (this.data && this.data.id) {
      customer.id = this.id;
    }
    return customer;
  }

  protected setFormValues(data: ICustomer): void {
    if (data) {
      this.id = data.id;
      this.customer = data.customer;
      this.addresses = data.addresses ?? [];
      this.createdAt = data.createdAt;
      this.updatedAt = data.updatedAt;
    }
  }

  protected init() {}
}
