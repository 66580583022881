
import { Component } from "vue-property-decorator";
import CreateMixin from "@/mixins/Crud/CreateMixin";
import { BaseDataCustomer } from "@/config/Modules";
import CustomerForm from "@/views/BaseData/Customer/CustomerForm.vue";
import { mixins } from "vue-class-component";
import { BaseDataCustomerStoreMixin } from "@/mixins/Stores/BaseData/CustomerStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import ICreateMixin from "@/mixins/interface/ICreateMixin";

@Component({
  components: { CustomerForm },
  mixins: [CreateMixin, BaseDataCustomerStoreMixin],
})
export default class CustomerCreate
  extends mixins(BaseDataCustomerStoreMixin, CreateMixin)
  implements ICreateMixin
{
  protected name = "CustomerCreate";

  public get resource(): string {
    return BaseDataCustomer.resource;
  }

  public get descriptionField(): string {
    return BaseDataCustomer.description_field;
  }

  public createItemAction(options: ICrudOptions): any {
    return this.createBaseDataCustomerItemAction(options);
  }

  public redirectAfterCreateSuccess(responseData: any): void {
    this.$router.replace({
      name: "BaseDataCustomerUpdate",
      params: { id: responseData.id },
    });
  }
}
